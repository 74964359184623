import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import axios from 'axios'



function TablaUsuarios(props) {
  const usuarios = props.usuarios
  const API_URL = process.env.REACT_APP_API_URL

  

  const displayItems = () => {
    if (usuarios) {
      if (usuarios.length > 0) {
        return usuarios.map((usuario, index) => {
          return (
            <tr key={index}>
              <td>{usuario.first_name} {usuario.last_name}</td>
              <td>{usuario.phone}</td>
              <td>{usuario.email}</td>
              <td>{usuario.company}</td>
              <td>{usuario.employees}</td>
              <td>{usuario.state}</td>
              <td>{usuario.city}</td>
              <td>{usuario.total_exp}</td>
              <td>{usuario.expiration_time}</td>
             
            </tr>
          )
        })
      }
    }
  }

  return <>{displayItems()}</>
}

export {TablaUsuarios}



