import React, {useState} from 'react'
import {Link} from 'react-router-dom'



export function RegistrationValidation() {
  

  return (
    <>
     
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Hemos validado tu correo</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4 mb-10'>
            Gracias por validar tu correo, ya puedes comenzar a utilizar ADINTE.
          </div>
          {/* end::Link */}

          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-lg  btn-primary fw-bolder mb-10'
            >
              Ingresar
            </button>
          </Link>
        </div>
    </>
  )
}
