import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, updatePass} from '../core/_requests'
import axios from 'axios'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const API_URL = process.env.REACT_APP_API_URL

const initialValues = {
  password: '',
  changepassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('No se ingresó contraseña.')
    .min(8, 'La contraseña es muy corta - debe tener 8 caracteres mínimo.')
    .matches(/[a-zA-Z]/, 'Las contraseñas solo pueden contener caracteres a-z A-Z.'),
  changepassword: Yup.string()
    .required('Se requiere confirmación de contraseña.')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Las contraseñas no coinciden'),
    }),
})

function ResetPassword() {
  const [urlParams, setUrlParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [tokenValid, setTokenValid] = useState(false)

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        updatePass(urlParams.get('key'), values.password)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Eeeor al reestablecer la contraseña')
          })
      }, 1000)
    },
  })

  const validateResetToken = () => {
    const dataForm = {
      key: urlParams.get('key'),
      token: urlParams.get('token'),
    }

    axios
      .post(API_URL + '/validateResetToken', dataForm, {
        // receive two parameter endpoint url ,form data
      })
      .then(
        (res) => {
          // then print response status
          console.log(res.data.status)
          setTokenValid(res.data.status)
        },
        (error) => {
          alert(error)
        }
      )
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    validateResetToken()
  }, [])

  return (
    <>
      {tokenValid ? (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Reestablecer contraseña</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>Ingresa tu nueva contraseña.</div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                Sorry, looks like there are some errors detected, please try again.
              </div>
            </div>
          )}

          {hasErrors === false && (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                <p>La contraseña se reestableció correctamente</p>
                <p><a href="/auth"><b>INICIA SESIÓN</b></a></p>
              </div>
              
            </div>
          )}
          {/* end::Title */}

          {/* begin::Form group */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Nueva Contraseña</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Contraseña'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>
              Use 8 o más caracteres con una combinación de letras, números y símbolos.
              {/* Use 8 or more characters with a mix of letters, numbers & symbols. */}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirmar contraseña</label>
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              {!loading && <span className='indicator-label'>Enviar</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancelar
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      ) : (
        <>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Reestablecer contraseña</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-bold fs-4'>El link expiró.</div>
            {/* end::Link */}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Regresar
              </button>
            </Link>{' '}
          </div>
        </>
      )}
      {''}
    </>
  )
}

export {ResetPassword}
